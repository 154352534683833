import React from "react";

import HelpCenterItem from "../../containers/HelpCenter/HelpCenterItem";

const HelpCenterArticle = ({ path }) => {
  return (
    <HelpCenterItem path={path}>
      <div>
        <div>
          <p>
            Try logging out and logging back in first. If this doesn’t work
            follow the steps below:
          </p>
        </div>
        <ul>
          <li>
            Make sure you are using the the same platform where you initially
            purchased from (Apple/Android)
          </li>
          <li>
            On your device make sure you are using the same Apple / Google ID
            which you used to make the initial purchase.
          </li>
          <li>Make sure you have an active internet connection</li>
          <li>Navigate to Settings (4th tab) and tap “Restore Purchase”</li>
        </ul>
      </div>
    </HelpCenterItem>
  );
};

export default HelpCenterArticle;
